<template>
    <section class='row related-articles'>
      <div class="offset-lg-1 col-12 col-lg-10">

        <h2 class="section-title">{{ $t('relatedArticles.title') }}</h2>

        <!-- Archive of Posts -->
        <div class="row">
            <PostPreviewNewsAndAnnouncements :category="'News'" :image="'bg-post-1.png'" :title="'Blockchain and Cryptos Potential Yet Promising Impact on the Finance Industry Blockchain and Cryptos Potential Yet Promising Impact on the Finance Industry'" :description="'At the risk of reinforcing the industry reputation for hyperbole, the advent of blockchain technology probably ranks up there with some of the most influential technological developments of the 21st century.'" :postDate="'29-1-2019 12:32 PM'" :author="'Michelle Ang'"/>
            <PostPreviewNewsAndAnnouncements :category="'Announcements'" :image="'bg-post-2.jpg'" :title="'CEZA grants license to 6x.com'" :description="'At the risk of reinforcing the industry reputation for hyperbole, the advent of blockchain technology probably ranks up there with some of the most influential technological developments of the 21st century.'" :postDate="'29-1-2019 12:32 PM'" :author="'Michelle Ang'"/>
        </div>

      </div>
    </section>
</template>

<script>
// @ is an alias to /src
import PostPreviewNewsAndAnnouncements from '@/components/partials/PostPreviewNewsAndAnnouncements.vue';

export default {
  name: "RelatedArticles",
  components: {
    PostPreviewNewsAndAnnouncements,
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/css/scss/single-news-and-announcements/related-articles.scss';
</style>