<template>
    <section class='row section-page-header'>
        <div class="overlay-bg-single-news-and-announcements"></div>

        <!-- Navbar: Not the right way to do it, will require refactoring -->
        <MainNav />
        
        <div class="offset-lg-1 col-11 text-left">
          <router-link class="back-button"  to="/archive-news-and-announcements"><img class="icon-back-button" src="../../assets/img/back-button.svg" /> BACK</router-link>
        </div>

        <!-- Content -->
        <div class="offset-lg-1 col-12 col-lg-6 text-left content">
          <span class="category">{{ category }}</span>
          <h2 class="title">{{ title }}</h2>
          <span class="date-and-time"><img class="icon" src="@/assets/img/time-and-date-white.svg"/> {{ getPostDate }}</span>
        </div>
    </section>
</template>

<script>
// @ is an alias to /src
import MainNav from '@/components/MainNav.vue';

export default {
  name: "SectionPageHeader",
  components: {
    MainNav,
  },
  props: {
    category: String, 
    title: String,
    postDate: String,
  },
  computed: {
    getPostDate: function() {
      let postDate = new Date(this.postDate);

      let date = postDate.getDate();
      let month = (postDate.getMonth() + 1);
      let year = postDate.getFullYear();

      if (date < 10) {
          date = '0' + date;
      }

      if (month < 10) {
          month = '0' + month;
      }

      let hour = postDate.getTime();
      let minute = postDate.getMinutes();
      let period = (hour < 12) ? 'AM' : 'PM';

      return `${date}-${month}-${year} ${hour}:${minute} ${period}`;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../assets/css/scss/single-news-and-announcements/section-page-header.scss';
</style>