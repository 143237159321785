<template>
  <div class="single-news-and-announcements page">
    <SectionPageHeader :category="news.category" :title="news.enTitle" :postDate="news.postDate" />
    <SingleNewsAndAnnouncements :author="news.author" :content="news.enContent" />
    <RelatedArticles />
    <Footer />
  </div>
</template>

<script>
import SectionPageHeader from "@/components/single-news-and-announcements/SectionPageHeader.vue";
import SingleNewsAndAnnouncements from "@/components/single-news-and-announcements/SingleNewsAndAnnouncements.vue";
import RelatedArticles from "@/components/single-news-and-announcements/RelatedArticles.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";

export default {
  name: "single-news-and-announcements",
  head: {
    title: { inner: 'Single News And Announcements' }
  },
  components: {
    SectionPageHeader,
    SingleNewsAndAnnouncements,
    RelatedArticles,
    Footer
  },
  data: function() {
    return {
      news: null,
    };
  },
  created: function() {
    // this.getNewsAndAnnouncement();
  },
  methods: {
    async getNewsAndAnnouncement() {
      let id = this.$route.params.id;
      await axios.get(`http://testdeployd.aezypay.com/announcements/${id}`).then(res => (this.news = res.data));
    },
  },
}
</script>

<style lang="scss" scoped>
</style>

