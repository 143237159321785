<template>
    <section class='row single-news-and-annoucements-main-content'>

      <!-- Post Meta Description and Sharing Links -->
      <div class="offset-lg-1 col-12 col-lg-3 order-2 order-lg-1">
        <div class="post-meta">
          <img class="author-image" src="@/assets/img/single-news-and-announcements/author-image.svg"/>
          <h3 class="author-name">{{ author }}</h3>
        </div>

        <div class="social-media-icons">
          <p class="share">{{ $t('newsAndAnnouncement.share') }}</p>
          <div class="icon-circle">
            <img class="icon" src="@/assets/img/single-news-and-announcements/share-fb.svg"/>
            </div>
          <div class="icon-circle">
            <img class="icon" src="@/assets/img/single-news-and-announcements/share-twitter.svg"/>
            </div>
          <div class="icon-circle">
            <img class="icon" src="@/assets/img/single-news-and-announcements/share-linkedin.svg"/>
            </div>
          <div class="icon-circle">
            <img class="icon" src="@/assets/img/single-news-and-announcements/share-google.svg"/>
            </div>
        </div>
      </div>

      <!-- Post Content -->
      <div class="col-12 col-lg-7 order-1  order-lg-2 text-justify main-content" v-html="content">
        
      </div>

    </section>
</template>

<script>
// @ is an alias to /src
import MainNav from '@/components/MainNav.vue';

export default {
  name: "SectionPageHeader",
  components: {
    MainNav,
  },
  props: {
    author: String,
    content: String, 
    image: String,
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/css/scss/single-news-and-announcements/single-news-and-announcements.scss';
</style>